<template>
  <div>
    <PageHeader :title="title" />
    <div class="tw-pt-5 tw-pb-16 tw-mx-auto">
      <FormulateForm
        #default="{ isLoading }"
        v-model="contact"
        name="contactForm"
        invalid-message="Gelieve de verplichte velden correct in te vullen."
        class="tw-px-4 tw-py-2 tw-bg-white tw-rounded"
        @submit="submit"
      >
        <h3 class="tw-text-xl tw-font-light">Contact bewerken</h3>

        <div class="tw-grid md:tw-grid-cols-2 tw-gap-x-6">
          <div>
            <FormulateInput
              type="select"
              name="type"
              label="Contacttype"
              :options="{ P: 'Persoon', B: 'Bedrijf' }"
              value="P"
            />
            <div v-show="contact.type === 'B'">
              <FormulateInput
                type="text"
                name="company_name"
                label="Bedrijfsnaam"
                :validation="contact.type === 'B' ? 'required' : ''"
                :show-required-label="true"
              />
              <FormulateInput
                type="select"
                name="company_type"
                label="Bedrijfstype"
                placeholder="Selecteer het bedrijfstype"
                :options="COMPANY_TYPES"
              />
            </div>
            <div v-show="contact.type === 'P'">
              <div class="tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-4">
                <FormulateInput
                  type="text"
                  name="first_name"
                  label="Voornaam"
                  :validation="contact.type === 'P' ? 'required' : ''"
                  :show-required-label="true"
                  outer-class="tw-m-0"
                />
                <FormulateInput
                  type="text"
                  name="last_name"
                  label="Naam"
                  :validation="contact.type === 'P' ? 'required' : ''"
                  :show-required-label="true"
                  outer-class="tw-m-0"
                />
                <FormulateInput
                  type="select"
                  name="gender"
                  label="Geslacht"
                  placeholder="Selecteer geslacht"
                  :options="{ M: 'Man', F: 'Vrouw', U: 'Onbekend' }"
                  outer-class="tw-m-0"
                />
                <FormulateInput
                  type="select"
                  name="marital_status"
                  label="Burgerlijke staat"
                  placeholder="Selecteer burgerlijke staat"
                  :options="{
                    0: 'Onbekend',
                    1: 'Gehuwd',
                    2: 'Ongehuwd',
                    4: 'Weduwe/weduwnaar',
                    5: 'Gescheiden',
                    6: 'Wettelijk samenwonend'
                  }"
                  outer-class="tw-m-0"
                />
                <FormulateInput
                  type="date"
                  name="birth_date"
                  label="Geboortedatum"
                  :validation="contact.type === 'P' ? 'bail|optional|date:YYYY-MM-DD' : ''"
                  outer-class="tw-m-0"
                />
                <FormulateInput
                  type="autocomplete"
                  auto-complete-type="birthplace"
                  name="birth_place"
                  label="Geboorteplaats"
                  placeholder="Zoek op plaatsnaam"
                  outer-class="tw-m-0"
                  @callback="birthPlaceSelected"
                />
              </div>
              <FormulateInput
                type="text"
                name="national_registry_number"
                label="Rijksregisternummer"
              />
            </div>
            <FormulateInput
              type="autocomplete"
              auto-complete-type="street"
              name="street"
              label="Straat"
              placeholder="Zoek een straat"
              @callback="streetSelected"
            />
            <div class="tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-4">
              <FormulateInput
                type="text"
                name="number"
                label="Huisnummer"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="text"
                name="box"
                label="Bus"
                outer-class="tw-m-0"
              />
            </div>
            <FormulateInput
              type="autocomplete"
              auto-complete-type="city"
              name="city"
              label="Plaats"
              placeholder="Zoek op postcode of plaatsnaam"
            />
            <FormulateInput
              type="checkbox"
              name="is_archived"
              label="Gearchiveerd"
            />
          </div>

          <div>
            <FormulateInput
              type="select"
              name="language"
              label="Voorkeurstaal"
              placeholder="Selecteer taal"
              :options="USER_LANG_OPTIONS"
              value="nl"
            />
            <FormulateInput
              type="text"
              name="vat"
              label="Ondernemingsnummer"
              placeholder="BE 0###.###.###"
              help="Bv.: BE 0682.871.387"
              validation="bail|optional|validVatNumber"
            />
            <FormulateInput
              type="checkbox"
              name="is_vat_liable"
              label="Btw-plichtig"
              validation="hasVatNumber"
              :validation-rules="{ hasVatNumber }"
              :validation-messages="{
                hasVatNumber: 'Een btw-nummer bij btw-plichtigheid is vereist',
              }"
            />
            <FormulateInput
              type="text"
              name="bank_account_number"
              label="Rekeningnummer"
            />
            <FormulateInput
              type="text"
              name="website"
              label="Website"
            />
            <fieldset class="tw-m-0 tw-px-4 tw-border-gray-cc tw-rounded">
              <legend class="tw--mx-1 tw-my-0 tw-px-1 tw-w-auto tw-font-bold tw-text-sm tw-text-tg-color">
                Contactmethodes
                <span v-if="contact.type === 'P'" class="input-error">(verplicht)</span>
              </legend>
              <FormulateInput
                v-model.trim="contact.email"
                type="email"
                name="email"
                label="E-mail"
                validation="bail|optional|email"
              />
              <FormulateInput
                type="dial_code_tel"
                name="mobile"
                label="Gsm"
                validation="bail|optional|phone"
              />
              <FormulateInput
                type="dial_code_tel"
                name="phone"
                label="Telefoon"
                validation="bail|optional|phone"
              />
              <span v-if="noContactMethod" v-text="noContactMethod" class="input-error"/>
            </fieldset>
            <FormulateInput
              v-model.trim="contact.email2"
              type="email"
              name="email2"
              label="E-mail 2"
              validation="bail|optional|email"
            />
            <FormulateInput
              v-model.trim="contact.email3"
              type="email"
              name="email3"
              label="E-mail 3"
              validation="bail|optional|email"
            />
          </div>
        </div>

        <span v-show="noContactMethod" v-text="noContactMethod" class="tw-my-8 input-error" />
        <FormulateErrors />

        <div class="tw-flex tw-flex-wrap tw-gap-x-4">
          <FormulateInput
            type="submit"
            :disabled="isLoading || noContactMethod"
            :input-class="['tw-w-full']"
            :outer-class="['tw-w-full md:tw-w-auto']"
          >
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
              ]"
            />
            Opslaan
          </FormulateInput>
          <router-link
            :to="{ name: 'ContactDetails', params: { id: contactId } }"
            class="action tw-my-4 tw-mx-0 tw-px-6 tw-py-2 tw-bg-error !tw-no-underline tw-text-sm md:tw-text-base tw-w-full md:tw-w-auto"
          >
            <i class="fa fa-times tw-mr-2"/> Annuleren
          </router-link>
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import COMPANY_TYPES from '@/components/contacts/company_types'

import { formatContact, USER_LANG_OPTIONS } from '@/utils/helpers'
import { getContactById, updateContact, getContacts } from '@/services/contacts'
import { questionModal, startLoadingModal, successModal, errorModal } from '@/modalMessages'
import { getPaymentTerms } from '@/services/apiService'
import ERROR_CODE_MAP from '@/forms/errorCodes.json'

export default {
  name: 'ContactEdit',
  components: {
    PageHeader
  },
  constants: {
    COMPANY_TYPES,
    USER_LANG_OPTIONS
  },
  data () {
    return {
      contact: {},
      paymentTerms: []
    }
  },
  watch: {
    contactId: {
      immediate: true,
      handler (contactId) {
        if (!this.paymentTerms.length) {
          this.loadPaymentTerms()
        }
        if (contactId) this.loadContactById(contactId)
      }
    }
  },
  computed: {
    contactId () {
      return this.$route.params.id
    },
    title () {
      return this.contact?.display_name || 'Contact'
    },
    noContactMethod () {
      // It is mandatory only for contact type "Person"
      const { email, phone, mobile, type } = this.contact
      return !(email || phone || mobile) && type === 'P'
        ? 'Er is minimaal één e-mailadres, telefoonnummer of mobiel telefoonnummer vereist.'
        : false
    }
  },
  methods: {
    streetSelected (street) {
      if (street?.city) this.contact.city = street.city
      if (street?.number) this.contact.number = street.number
    },
    birthPlaceSelected (city) {
      this.contact.birth_place = city
    },
    hasVatNumber ({ value }) {
      return value ? !!this.contact.vat : true
    },

    async loadPaymentTerms () {
      const response = await getPaymentTerms()
      this.paymentTerms = response.data?.results
      return response
    },

    async loadContactById (contactId) {
      try {
        const response = await getContactById(contactId)
        this.contact = response.data
        if (this.contact.payment_term) {
          this.$set(this.contact, 'payment_term', this.contact.payment_term.value)
        }
        return response
      } catch (error) {
        errorModal('Kan het contact niet laden, gelieve opnieuw te proberen.')
      }
    },
    async validateContact (email) {
      // If no email is entered, no contact can be searched, proceed, as the validation passes in that case.
      if (!email) return true

      const contactExists = await getContacts({ params: { email } })
      const contact = await contactExists.data.results[0]
      // If no contact found, or the contact that we are editing is found, validation is passed
      if (!contact || contact.id === this.contact.id) return true

      const result = await questionModal(`Er bestaat reeds een contact met e-mailadres ${email} onder de naam ${contact.display_name}. Wens je het e-mailadres van dit contact toch aan te passen?`)
      return result.value
    },
    async submit (data) {
      try {
        if (data.is_archived) {
          const result = await questionModal('Ben je zeker dat je dit contact wilt archiveren?')
          if (!result.value) return
        }
        startLoadingModal('Contacten bewerken...')
        const payload = formatContact(data)
        const proceed = await this.validateContact(payload.email)

        if (proceed) {
          const response = await updateContact(payload)
          const contact = response.data
          this.$router.push({ name: 'ContactDetails', params: { id: contact.id } })
          successModal('Contactgegevens succesvol opgeslagen')
        }

        return proceed
      } catch (error) {
        if (error.response?.data?.code?.[0] === 'INVALID_VAT_VIES') {
          const result = await questionModal("Volgens de officiële VIES-databank is dit geen bestaand btw-nummer. Gelieve het btw-nummer dubbel te controleren. Klik op 'Ja' om verder te gaan.", '')
          if (!result.value) return
          this.contact.skip_vat_check = true
          return await this.submit(this.contact)
        }
        let errorMessage = ERROR_CODE_MAP[error.response?.data?.code]
        if (!errorMessage) {
          const errors = []
          Object.values(error.response?.data).forEach(val => errors.push(val[0]))
          errorMessage = errors.length ? errors.join(' / ') : 'Kan contact niet opslaan, gelieve opnieuw te proberen'
        }
        errorModal(errorMessage)
      }
    }
  }
}
</script>
